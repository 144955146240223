import React from "react"
import { Link } from "gatsby"
import Shape1 from "../../assets/images/app/orbital4-appicon.png"
import AppStoreBadge from "../../assets/images/app/appstore/Download_on_the_App_Store_Badge.png"
import GooglePlayBadge from "../../assets/images/app/googlePlay/google-play-badge.png"

const FeaturedServices = () => {
  return (
    <div className="featured-services-area pt-100 pb-70">
      <div className="container">
        <h3 className="">Products</h3>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-boxes-box">
              <img src={Shape1} />
              {/* <div className="icon">
                <i className="flaticon-research"></i>

                <div className="circles-box">
                  <div className="circle-one"></div>
                  <div className="circle-two"></div>
                </div>
              </div> */}

              <h3>
                {/* <Link to="/single-services">comming soon</Link> */}
                orbital 4
              </h3>

              <p>カラフルな軌道を駆け巡る</p>
              <a
                href="https://apps.apple.com/jp/app/orbital4/id1479272181"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppStoreBadge} style={{ width: 100 }} />
              </a>
              {/* <div style={{ marginLeft: 1, marginRight: 1 }} /> */}
              <br />
              <br />
              <a
                href="https://play.google.com/store/apps/details?id=com.kuro.oribital4android"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlayBadge} style={{ width: 105 }} />
              </a>

              {/* <Link to="/single-services" className="learn-more-btn">
                                <i className="left-icon flaticon-nexst-button"></i> 
                                Learn More <i className="right-icon flaticon-next-button"></i>
                            </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-boxes-box">
              <div className="icon">
                <i className="flaticon-speed"></i>
                <div className="circles-box">
                  <div className="circle-one"></div>
                  <div className="circle-two"></div>
                </div>
              </div>

              <h3>
                {/* <Link to="/single-services">Website Optimization</Link> */}
                comming soon
              </h3>

              <p>...</p>

              {/* <Link to="/single-services" className="learn-more-btn">
                <i className="left-icon flaticon-next-button"></i>
                Learn More <i className="right-icon flaticon-next-button"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div className="single-boxes-box">
              <div className="icon">
                <i className="flaticon-email-marketing"></i>
                <div className="circles-box">
                  <div className="circle-one"></div>
                  <div className="circle-two"></div>
                </div>
              </div>

              <h3>
                comming soon
                {/* <Link to="/single-services">Email Marketing</Link> */}
              </h3>

              <p>...</p>

              {/* <Link to="/single-services" className="learn-more-btn">
                <i className="left-icon flaticon-next-button"></i>
                Learn More <i className="right-icon flaticon-next-button"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedServices
